import React from 'react';
import { Helmet } from 'react-helmet';
import { useDeviceOrientation } from 'App/Game/hooks';
import AdService from 'App/Services/AdService';
import BubbleLoader from 'App/React/_UI/BubbleLoader';
import { vliRewardIdsMap } from '../../../../config';
import OrientationMessage from '../../../OrientationMessage';
import Game from '../Game';

const GameContainer = ({
    gameAnalytics,
    gaAnalytics,
    gameConfig,
    preroll,
    adProvider,
    createPushLead,
    updateLead,
}) => {
    const { orientation, blockGameRender, orientationMessage } =
        useDeviceOrientation(gameAnalytics, gaAnalytics);

    if (!orientation)
        return (
            <BubbleLoader backgroundColor={'#440494'} loaderColor={'#FFFFFF'} />
        );
    if (blockGameRender)
        return <OrientationMessage visible={orientationMessage} />;

    return (
        <>
            {orientationMessage && (
                <OrientationMessage visible={orientationMessage} />
            )}
            {adProvider === 'adinplay' && (
                <Helmet>
                    <script
                        async
                        type='text/javascript'
                        src='//api.adinplay.com/libs/aiptag/pub/RYM/snakecolorbrake.com/tag.min.js'></script>
                </Helmet>
            )}
            {adProvider === 'vli' && (
                <Helmet>
                    <script
                        type='text/javascript'
                        src='//cdn.vlitag.com/w/c2fb798d-6385-4c5c-8e8f-de96e1b1da11.js'
                        async
                        defer></script>
                </Helmet>
            )}
            <AdService
                rewardIds={vliRewardIdsMap}
                adProvider={adProvider}
                gameGA={gameAnalytics}
                ga={gaAnalytics}
                preroll={preroll}>
                <Game
                    adProvider={adProvider}
                    gameGa={gameAnalytics}
                    ga={gaAnalytics}
                    gameConfig={gameConfig}
                    orientation={orientation}
                    createPushLead={createPushLead}
                    updateLead={updateLead}
                />
            </AdService>
        </>
    );
};

export default GameContainer;
