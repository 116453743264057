import React, { useRef } from 'react';
import {
    useLevelLoaded,
    useLevelStarted,
    useResultHide,
    useResultShow,
    usePauseHide,
    usePauseShow,
    useCenterAdBanner,
} from '../Game/hooks';

const GameAdBanners = ({
    adProvider,
    setStartPreroll,
    addEventListener,
    removeEventListener,
}) => {
    const refHpBannerCenter = useRef(null);
    const refHpBannerBottom = useRef(null);
    const refGameplayBannerBottom = useRef(null);
    const refPauseBannerCenter = useRef(null);
    const refPauseBannerBottom = useRef(null);
    const refRestartBannerCenter = useRef(null);
    const refRestartBannerBottom = useRef(null);

    useLevelLoaded(
        refHpBannerCenter,
        refHpBannerBottom,
        addEventListener,
        removeEventListener
    );
    useLevelStarted(
        refHpBannerCenter,
        refHpBannerBottom,
        refGameplayBannerBottom,
        addEventListener,
        removeEventListener
    );
    useResultShow(
        refRestartBannerCenter,
        refRestartBannerBottom,
        refGameplayBannerBottom,
        setStartPreroll,
        addEventListener,
        removeEventListener
    );
    useResultHide(
        refRestartBannerCenter,
        refRestartBannerBottom,
        addEventListener,
        removeEventListener
    );
    usePauseShow(
        refPauseBannerCenter,
        refPauseBannerBottom,
        refGameplayBannerBottom,
        addEventListener,
        removeEventListener
    );
    usePauseHide(
        refPauseBannerCenter,
        refPauseBannerBottom,
        refGameplayBannerBottom,
        addEventListener,
        removeEventListener
    );
    useCenterAdBanner(
        refHpBannerCenter,
        refRestartBannerCenter,
        addEventListener,
        removeEventListener
    );

    return (
        <>
            {adProvider === 'adinplay' && (
                <>
                    {/* adinplay video ad placement */}
                    <div id='snakecolorbrake-com_preroll' />
                    {/* adinplay ad center banner placements */}
                    <div
                        ref={refHpBannerCenter}
                        data-ga-id='adinplay_300x250_HP'
                        className='center-banner'>
                        <div id='snakecolorbrake-com_300x250_HP' />
                    </div>
                    <div
                        ref={refRestartBannerCenter}
                        data-ga-id='adinplay_300x250_restart'
                        className='center-banner'>
                        <div id='snakecolorbrake-com_300x250_restart' />
                    </div>
                    <div
                        ref={refPauseBannerCenter}
                        data-ga-id='adinplay_300x250_pause'
                        className='center-banner'>
                        <div id='snakecolorbrake-com_300x250_pause' />
                    </div>
                    {/* adinplay ad bottom banner placements */}
                    <div
                        ref={refHpBannerBottom}
                        data-ga-id='adinplay_320x50_HP'
                        className='footer-banner-hp'>
                        <div id='snakecolorbrake-com_320x50_HP' />
                    </div>
                    <div
                        ref={refGameplayBannerBottom}
                        data-ga-id='adinplay_320x50_gameplay'
                        className='footer-banner-gameplay'>
                        <div id='snakecolorbrake-com_320x50_gameplay' />
                    </div>
                    <div
                        ref={refRestartBannerBottom}
                        data-ga-id='adinplay_320x50_restart'
                        className='footer-banner-restart'>
                        <div id='snakecolorbrake-com_320x50_restart' />
                    </div>
                    <div
                        ref={refPauseBannerBottom}
                        data-ga-id='adinplay_320x50_pause'
                        className='footer-banner-pause'>
                        <div id='snakecolorbrake-com_320x50_pause' />
                    </div>
                </>
            )}
            {adProvider === 'vli' && (
                <>
                    {/* vli ad center banner placements */}
                    <div
                        ref={refHpBannerCenter}
                        data-ga-id='vli_300x250_hp'
                        className='center-banner'>
                        <div data-ad-slot='pw_17220' className='adsbyvli' />
                    </div>
                    <div
                        ref={refRestartBannerCenter}
                        data-ga-id='vli_300x250_restart'
                        className='center-banner'>
                        <div data-ad-slot='pw_17871' className='adsbyvli' />
                    </div>
                    <div
                        ref={refPauseBannerCenter}
                        data-ga-id='vli_300x250_pause'
                        className='center-banner'>
                        <div data-ad-slot='pw_17869' className='adsbyvli' />
                    </div>
                    {/* vli ad bottom banner placements */}
                    <div
                        data-ga-id='vli_320x50_hp'
                        ref={refHpBannerBottom}
                        className='footer-banner-hp'>
                        <div data-ad-slot='pw_17216' className='adsbyvli' />
                    </div>
                    <div
                        data-ga-id='vli_320x50_gameplay'
                        ref={refGameplayBannerBottom}
                        className='footer-banner-gameplay'>
                        <div data-ad-slot='pw_17868' className='adsbyvli' />
                    </div>
                    <div
                        data-ga-id='vli_320x50_restart'
                        ref={refRestartBannerBottom}
                        className='footer-banner-restart'>
                        <div data-ad-slot='pw_17872' className='adsbyvli' />
                    </div>
                    <div
                        data-ga-id='vli_320x50_pause'
                        ref={refPauseBannerBottom}
                        className='footer-banner-pause'>
                        <div data-ad-slot='pw_17870' className='adsbyvli' />
                    </div>
                </>
            )}
        </>
    );
};

export default GameAdBanners;
