import * as Sentry from '@sentry/browser';
import { consoleInfoLog } from '../../Helpers/consoleInfoLog';

const gameanalytics = require('gameanalytics');

/**
 * Game analytics service provider
 *
 * @param gameKey - string (required)
 * @param gameSecret - string (required)
 * @param environment - "production" || "development" || "local" (required)
 * @param build - string (required)
 * @param userId - string (optional)
 */
export default class GameAnalyticsService {
    constructor(gameKey, gameSecret, environment, build, userId) {
        this.userId = userId || 'default';
        this.initFailed = false;

        this.init(gameKey, gameSecret, environment, build, userId);
    }

    /**
     * state setter function
     *
     * @param valueName - string (required)
     * @param value - any (required)
     */
    setState(valueName, value) {
        this[valueName] = value;
    }

    /**
     * Init game analytics
     *
     * @param gameKey - string (required)
     * @param gameSecret - string (required)
     * @param environment - "production" || "development" || "local" (required)
     * @param build - string (required)
     * @param userId - string (optional)
     */
    init(gameKey, gameSecret, environment, build, userId) {
        try {
            gameanalytics.GameAnalytics.setEnabledVerboseLog(
                environment !== 'production'
            );
            userId && gameanalytics.GameAnalytics.configureUserId(userId);

            gameanalytics.GameAnalytics.configureBuild(build);
            gameanalytics.GameAnalytics.initialize(gameKey, gameSecret);
            consoleInfoLog('start Init Game Analytics', '#ACC8E5', '#112A46');
        } catch (e) {
            this.setState('initFailed', true);
            environment !== 'local' && Sentry.captureException(e);
        }
    }

    /**
     * Send design event to game analytics
     *
     * @param eventName - string (required)
     * @param value - float (optional)
     * @param fieldsJson - { key[string]: string || number } (optional)
     */
    sendDesignEvent(eventName, value, fieldsJson) {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.addDesignEvent(
            eventName,
            value && value,
            fieldsJson && JSON.parse(fieldsJson)
        );
    }

    /**
     * Send progression event to game analytics
     *
     * @param progressionStatus - "Start" | "Fail" | "Complete" | "Undefined" (required)
     * @param progression1 - string (optional)
     * @param progression2 - string (optional)
     * @param progression3 - string (optional)
     * @param score - number (optional)
     */
    sendProgressionEvent(
        progressionStatus,
        progression1,
        progression2,
        progression3,
        score
    ) {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.addProgressionEvent(
            gameanalytics.EGAProgressionStatus[progressionStatus],
            progression1,
            progression2,
            progression3,
            score
        );
    }

    /**
     * Send progression event to game analytics
     *
     * @param eventType - 'Show' | 'FailedShow' | 'RewardReceived' | 'Clicked' | 'Undefined' (required) - (can be extended according to GA documentation)
     * @param adType - 'RewardedVideo' | 'Banner' | 'Video' | 'Undefined'  (required) - (can be extended according to GA documentation)
     * @param adSdkName - string (required) - name of the ad provider (adinplay), lowercase with no spaces or underscores
     * @param adPlacement - string (required) - placement/identifier of the ad within the game (end_of_game), max 64 characters
     * @param customFields - object (optional)

     */
    sendAdEvent(eventType, adType, adSdkName, adPlacement, customFields) {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.addAdEvent(
            gameanalytics.EGAAdAction[eventType],
            gameanalytics.EGAAdType[adType],
            adSdkName,
            adPlacement,
            customFields
        );
    }

    /**
     * Send error event to game analytics
     *
     * @param errorType - 'Error' | 'Warning' | 'Info' | 'Debug' | 'Critical' | 'Undefined' (required)
     * @param message - string (required)
     */
    sendErrorEvent(errorType, message) {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.addErrorEvent(
            gameanalytics.EGAErrorSeverity[errorType],
            message
        );
    }

    /**
     * Starts game analytics session
     */
    startGameSession() {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.startSession();
    }

    /**
     * Ends game analytics session
     */
    endGameSession() {
        if (this.initFailed) {
            return;
        }
        gameanalytics.GameAnalytics.endSession();
    }
}
