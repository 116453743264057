import React, { memo, useContext, useEffect } from 'react';
import { useTermsAndPrivacyContent } from 'App/Game/hooks';
import Modal from 'App/React/_UI/Modal/Modal';
import { updateUrlHash } from 'App/helpers';
import { PushNotificationContext } from '../../providers';
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import Advantages from './components/Advantages';
import Tutorial from './components/Tutorial';
import Footer from './components/Footer';
import './styles.scss';

const Landing = ({ adBlocker, updateState, setPhase, gameGA, ga }) => {
    const { showPushPhase } = useContext(PushNotificationContext);
    const [modalContent, isModalOpen, showTermsUse, hideTermsUse] =
        useTermsAndPrivacyContent();

    useEffect(() => {
        updateUrlHash('1');
    }, []);

    const playBtnClickHandler = () => {
        adBlocker && updateState({ showAdBlockerModal: true });

        if (!('Notification' in window)) {
            setPhase('game');
        } else {
            setPhase(showPushPhase ? 'push' : 'game');
        }

        gameGA?.sendDesignEvent('HP:PlayButton');
        ga?.basicGaEvent('hp', 'play_button');
    };

    return (
        <div className={'landing-phase'}>
            <main>
                <Hero
                    onBtnClick={playBtnClickHandler}
                    gameGA={gameGA}
                    ga={ga}
                />
                <About onBtnClick={playBtnClickHandler} />
                <Features />
                <Advantages onBtnClick={playBtnClickHandler} />
                <Tutorial onBtnClick={playBtnClickHandler} />
            </main>
            <Footer showTermsUse={showTermsUse} />
            {isModalOpen && (
                <Modal
                    title={modalContent ? modalContent.title : ''}
                    content={modalContent ? modalContent.content : ''}
                    onClose={hideTermsUse}
                />
            )}
        </div>
    );
};

export default memo(Landing);
