export default class UrlParams {
    static get refid1() {
        const refid1 =
            UrlParams.parameterByName('refid1') ||
            localStorage.getItem('refid1');

        if (refid1) {
            return refid1;
        } else {
            return '0';
        }
    }

    static get refid2() {
        const refid2 =
            UrlParams.parameterByName('refid2') ||
            localStorage.getItem('refid2');

        if (refid2) {
            return refid2;
        } else {
            return '0';
        }
    }

    static get refid3() {
        const refid3 =
            UrlParams.parameterByName('refid3') ||
            localStorage.getItem('refid3');

        if (refid3) {
            return refid3;
        } else {
            return '0';
        }
    }

    static get hash() {
        const hash = UrlParams.parameterByName('hash');

        if (hash) {
            return hash;
        } else {
            return undefined;
        }
    }

    static get rid() {
        const rid = UrlParams.parameterByName('rid');

        if (rid) {
            return rid;
        } else {
            return null;
        }
    }

    /**
     * get name param return the value of its url param
     * @param name
     * @param url
     * @returns {*}
     */
    static parameterByName(name, url) {
        if (!url) url = window.location.href;

        name = name.replace(/[\[\]]/g, '\\$&');

        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
