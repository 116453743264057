export default class SharpstarCallFailedError extends Error {
    constructor(message, errorCode) {
        super();
        this.name = 'SharpstarCallFailedError';
        this.code = errorCode;
        this.message =
            message || errorCode
                ? `${errorCode || ''} ${message || ''}`
                : 'Sharpstar call failed';
    }
}
