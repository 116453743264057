import { useCallback, useContext, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import * as Sentry from '@sentry/browser';
import { useAdService } from 'App/Services/AdService';
import { PushNotificationContext, PwaContext } from '../../../../providers';
import { shouldDisplayPreroll } from '../../../../helpers';
import { gameContainerBg } from '../../../../config';

/**
 * hook fires when game loaded, sets ad banner visibility and
 * update ad banner content (you can add any additional logic into handleLevelLoaded)
 *
 * @param centerHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomHpBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useLevelLoaded = (
    centerHpBannerRef,
    bottomHpBannerRef,
    addEventListener,
    removeEventListener
) => {
    const [loadedAttempt, setLoadedAttempt] = useState(0);
    const { updateAd, setBannerVisible } = useAdService();

    const handleLevelLoaded = useCallback(
        (attempt) => {
            setLoadedAttempt((prev) => ++prev);

            if (loadedAttempt === 0) {
                setBannerVisible(centerHpBannerRef.current, true);
                setBannerVisible(bottomHpBannerRef.current, true);
                updateAd(centerHpBannerRef.current, attempt);
                updateAd(bottomHpBannerRef.current, attempt);
            }
        },
        [
            centerHpBannerRef,
            bottomHpBannerRef,
            updateAd,
            setBannerVisible,
            loadedAttempt,
        ]
    );

    useEffect(() => {
        addEventListener('OnLevelLoaded', handleLevelLoaded);

        return () => removeEventListener('OnLevelLoaded', handleLevelLoaded);
    }, [addEventListener, removeEventListener, handleLevelLoaded]);
};

/**
 * hook that fires when game level started and
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomHpBannerRef - RefObject<HTMLDivElement>
 * @param bottomRefGameplayBanner - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useLevelStarted = (
    centerHpBannerRef,
    bottomHpBannerRef,
    bottomRefGameplayBanner,
    addEventListener,
    removeEventListener
) => {
    const [startedAttempt, setStartedAttempt] = useState(0);
    const { updateAd, setBannerVisible } = useAdService();

    const handleLevelStarted = useCallback(
        (attempt) => {
            setBannerVisible(bottomRefGameplayBanner.current, true);
            updateAd(bottomRefGameplayBanner.current, attempt);
            setStartedAttempt((prev) => ++prev);

            if (startedAttempt === 0) {
                setBannerVisible(centerHpBannerRef.current, false);
                setBannerVisible(bottomHpBannerRef.current, false);
            }
        },
        [
            centerHpBannerRef,
            bottomHpBannerRef,
            bottomRefGameplayBanner,
            updateAd,
            setBannerVisible,
            startedAttempt,
        ]
    );

    useEffect(() => {
        addEventListener('OnLevelStarted', handleLevelStarted);

        return () => removeEventListener('OnLevelStarted', handleLevelStarted);
    }, [addEventListener, removeEventListener, handleLevelStarted]);
};

/**
 * hook fires when game result screen is showing, handler gets current game attempt as parameter
 * and handles result screen ad banners display
 *
 * @param centerRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param setStartPreroll - () => void (sets startPreroll state)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useResultShow = (
    centerRestartBannerRef,
    bottomRestartBannerRef,
    bottomGameplayBannerRef,
    setStartPreroll,
    addEventListener,
    removeEventListener
) => {
    const {
        updateAd,
        setBannerVisible,
        showPreroll,
        preroll: { showStartAttempt, showInterval },
    } = useAdService();

    const handleOnResultShow = useCallback(
        (attempt) => {
            // show preroll
            if (shouldDisplayPreroll(attempt, showInterval, showStartAttempt)) {
                setStartPreroll(true);
                showPreroll(attempt);
            }

            // update banners
            setBannerVisible(centerRestartBannerRef.current, true);
            setBannerVisible(bottomRestartBannerRef.current, true);
            updateAd(centerRestartBannerRef.current, attempt);
            updateAd(bottomRestartBannerRef.current, attempt);
            setBannerVisible(bottomGameplayBannerRef.current, false);
        },
        [
            centerRestartBannerRef,
            bottomRestartBannerRef,
            bottomGameplayBannerRef,
            updateAd,
            showStartAttempt,
            showInterval,
        ]
    );

    useEffect(() => {
        addEventListener('OnResultShow', handleOnResultShow);

        return () => removeEventListener('OnResultShow', handleOnResultShow);
    }, [addEventListener, removeEventListener, handleOnResultShow]);
};

/**
 * hook fires when game result screen is closed,
 *
 * @param centerRestartBannerRef - RefObject<HTMLDivElement>
 * @param bottomRestartBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useResultHide = (
    centerRestartBannerRef,
    bottomRestartBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { setBannerVisible } = useAdService();

    const handleOnResultHide = useCallback(() => {
        setBannerVisible(centerRestartBannerRef.current, false);
        setBannerVisible(bottomRestartBannerRef.current, false);
    }, [centerRestartBannerRef, bottomRestartBannerRef, setBannerVisible]);

    useEffect(() => {
        addEventListener('OnResultHide', handleOnResultHide);

        return () => removeEventListener('OnResultHide', handleOnResultHide);
    }, [addEventListener, removeEventListener, handleOnResultHide]);
};

/**
 * hook fires when game pause screen is showing, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const usePauseShow = (
    centerPauseBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseShow = useCallback(
        (attempt) => {
            setBannerVisible(centerPauseBannerRef.current, true);
            setBannerVisible(bottomPauseBannerRef.current, true);
            updateAd(centerPauseBannerRef.current, attempt);
            updateAd(bottomPauseBannerRef.current, attempt);
            setBannerVisible(bottomGameplayBannerRef.current, false);
        },
        [
            centerPauseBannerRef,
            bottomPauseBannerRef,
            bottomGameplayBannerRef,
            setBannerVisible,
            updateAd,
        ]
    );

    useEffect(() => {
        addEventListener('OnPauseShow', handleOnPauseShow);

        return () => removeEventListener('OnPauseShow', handleOnPauseShow);
    }, [addEventListener, removeEventListener, handleOnPauseShow]);
};

/**
 * hook fires when game pause screen is hiding, update ad banner content,
 * sets ad banner visibility (you can add any additional logic into handleLevelStarted)
 *
 * @param centerPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomPauseBannerRef - RefObject<HTMLDivElement>
 * @param bottomGameplayBannerRef - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const usePauseHide = (
    centerPauseBannerRef,
    bottomPauseBannerRef,
    bottomGameplayBannerRef,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleOnPauseHide = useCallback(
        (attempt) => {
            setBannerVisible(centerPauseBannerRef.current, false);
            setBannerVisible(bottomPauseBannerRef.current, false);
            setBannerVisible(bottomGameplayBannerRef.current, true);
            updateAd(bottomGameplayBannerRef.current, attempt);
        },
        [
            centerPauseBannerRef,
            bottomPauseBannerRef,
            bottomGameplayBannerRef,
            updateAd,
            setBannerVisible,
        ]
    );

    useEffect(() => {
        addEventListener('OnPauseHide', handleOnPauseHide);

        return () => removeEventListener('OnPauseHide', handleOnPauseHide);
    }, [addEventListener, removeEventListener, handleOnPauseHide]);
};

/**
 * hook fires to show or hide ad center banner on 'Home' or 'Result' game screens change
 *
 * @param refHpBannerCenter - RefObject<HTMLDivElement>
 * @param refRestartBannerCenter - RefObject<HTMLDivElement>
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useCenterAdBanner = (
    refHpBannerCenter,
    refRestartBannerCenter,
    addEventListener,
    removeEventListener
) => {
    const { updateAd, setBannerVisible } = useAdService();

    const handleHideCenterBanner = useCallback(
        (attempt, prevGameScreen) => {
            prevGameScreen === 'Home' &&
                setBannerVisible(refHpBannerCenter.current, false);
            prevGameScreen === 'Result' &&
                setBannerVisible(refRestartBannerCenter.current, false);
        },
        [refHpBannerCenter, refRestartBannerCenter, setBannerVisible, updateAd]
    );

    const handleShowCenterBanner = useCallback(
        (attempt, prevGameScreen) => {
            if (prevGameScreen === 'Home') {
                setBannerVisible(refHpBannerCenter.current, true);
                updateAd(refHpBannerCenter.current, attempt);
            }
            if (prevGameScreen === 'Result') {
                updateAd(refRestartBannerCenter.current, attempt);
                setBannerVisible(refRestartBannerCenter.current, true);
            }
        },
        [refHpBannerCenter, refRestartBannerCenter, setBannerVisible, updateAd]
    );

    useEffect(() => {
        addEventListener('OnCustomisationOpen', handleHideCenterBanner);
        addEventListener('OnCustomisationClose', handleShowCenterBanner);
        addEventListener('OnExtraRewardOpen', handleHideCenterBanner);
        addEventListener('OnExtraRewardClose', handleShowCenterBanner);
        addEventListener('OnPWAOpen', handleHideCenterBanner);
        addEventListener('OnPWAClose', handleShowCenterBanner);
        addEventListener('OnPushOpen', handleHideCenterBanner);
        addEventListener('OnPushClose', handleShowCenterBanner);

        return () => {
            removeEventListener('OnCustomisationOpen', handleHideCenterBanner);
            removeEventListener('OnCustomisationClose', handleShowCenterBanner);
            removeEventListener('OnExtraRewardOpen', handleHideCenterBanner);
            removeEventListener('OnExtraRewardClose', handleShowCenterBanner);
            removeEventListener('OnPWAOpen', handleHideCenterBanner);
            removeEventListener('OnPWAClose', handleShowCenterBanner);
            removeEventListener('OnPushOpen', handleHideCenterBanner);
            removeEventListener('OnPushClose', handleShowCenterBanner);
        };
    }, [
        addEventListener,
        removeEventListener,
        handleHideCenterBanner,
        handleShowCenterBanner,
    ]);
};

/**
 * hook fires when rewarded video ad is called
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useRewardStarted = (addEventListener, removeEventListener) => {
    const { showRewardedVideo } = useAdService();

    const handleOnRewardStarted = useCallback(
        (attempt, rewardType) => {
            showRewardedVideo(rewardType, attempt);
        },
        [showRewardedVideo]
    );

    useEffect(() => {
        addEventListener('OnRewardStarted', handleOnRewardStarted);

        return () =>
            removeEventListener('OnRewardStarted', handleOnRewardStarted);
    }, [addEventListener, removeEventListener, handleOnRewardStarted]);
};

/**
 * hook fires when pwa install event was called
 *
 * @param gameGa -new () => Class (initialized GameAnalytics Class instance)
 * @param ga - new () => Class (initialized Google Analytics Class instance)
 * @param updateLead - () => void () (optional function to update sharpstar lead function)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const usePwaInstall = (
    gameGa,
    ga,
    updateLead,
    addEventListener,
    removeEventListener
) => {
    const { deferredPwaPrompt, showInstallPrompt } = useContext(PwaContext);

    const handleOnInstallPWACalled = useCallback(
        (screenType) => {
            showInstallPrompt(
                gameGa,
                ga,
                deferredPwaPrompt,
                `Game:${screenType}`,
                updateLead
            );
        },
        [deferredPwaPrompt, gameGa, ga]
    );

    useEffect(() => {
        addEventListener('OnInstallPWACalled', handleOnInstallPWACalled);

        return () =>
            removeEventListener('OnInstallPWACalled', handleOnInstallPWACalled);
    }, [addEventListener, removeEventListener, handleOnInstallPWACalled]);
};

/**
 * hook fires when push native prompt is called from the game
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useEnablePush = (addEventListener, removeEventListener) => {
    const { updatePushCallSource, updateIsNativePromptWasClosed } = useContext(
        PushNotificationContext
    );

    const handleOnEnablePushCalled = useCallback((screenType) => {
        try {
            OneSignal.showNativePrompt().then(() => {
                updatePushCallSource(screenType);
                updateIsNativePromptWasClosed(true);
            });
        } catch (e) {
            Sentry.captureException(
                new TypeError('Fail to show push native prompt')
            );
        }
    }, []);

    useEffect(() => {
        addEventListener('OnEnablePushCalled', handleOnEnablePushCalled);

        return () =>
            removeEventListener('OnEnablePushCalled', handleOnEnablePushCalled);
    }, [addEventListener, removeEventListener, handleOnEnablePushCalled]);
};

/**
 * hook fires on game background change sets game container background color
 * with matching game background color (you can add any additional logic into handleOnColorChange)
 *
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 *
 * @return string
 */
export const useColorChange = (addEventListener, removeEventListener) => {
    const [gameBgColor, setGameBgColor] = useState('#440494');

    const handleOnColorChange = useCallback(
        (color) => {
            setGameBgColor(gameContainerBg[color]);
        },
        [setGameBgColor]
    );

    useEffect(() => {
        addEventListener('OnColorChange', handleOnColorChange);

        return () => removeEventListener('OnColorChange', handleOnColorChange);
    }, [addEventListener, removeEventListener, handleOnColorChange]);

    return gameBgColor;
};
