import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';
import { consoleInfoLog } from '../../Helpers/consoleInfoLog';
import { isProd } from '../../Helpers/enviroments';

export default class GoogleAnalytics {
    constructor(
        enabled,
        landingName,
        trackingId,
        gaOptions = {},
        gtagOptions = {}
    ) {
        this.landingName = landingName;
        this.enabled = enabled;

        this.init(trackingId, gaOptions, gtagOptions);
    }

    client() {
        return ReactGA;
    }

    init(trackingId, gaOptions, gtagOptions) {
        if (!this.enabled) return;

        ReactGA.initialize([{ trackingId, gaOptions, gtagOptions }]);
        consoleInfoLog('start Init Google Analytics 4', '#F9CCC8', '#2C2826');
    }

    pwaLaunched(pageType) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: pageType,
            action: `${pageType} Loaded`,
            label: this.landingName,
            nonInteraction: true,
        });
    }

    buttonClicked(eventName) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: eventName,
            label: this.landingName,
        });
    }

    pwaButtonClicked(pageType, buttonName) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: `${pageType} - ${buttonName} Button Click`,
            label: this.landingName,
        });
    }

    pwaInstalled() {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'PWA',
            action: 'PWA Install Success',
            label: this.landingName,
            nonInteraction: true,
        });
    }

    pwaInstallPromptAnswer(answer) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: 'PWA Install Prompted ' + answer,
            label: this.landingName,
        });
    }

    sendPageView(noneURLPath = '') {
        if (!this.enabled) {
            return;
        }

        ReactGA.send({
            hitType: 'pageview',
            page:
                window.location.pathname + noneURLPath + window.location.search,
        });
    }

    adBlock(isEnabled) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'adBlock',
            action: JSON.stringify(isEnabled),
            label: this.landingName,
            nonInteraction: true,
        });
    }

    initialDisplayMode() {
        if (!this.enabled) {
            return;
        }

        const isStandalone = window.matchMedia(
            '(display-mode: standalone)'
        ).matches;

        ReactGA.event({
            category: 'display mode',
            label: this.landingName,
            action: `Initial display mode - ${isStandalone ? 'PWA' : 'Browser'} Display`,
            nonInteraction: true,
        });
    }

    displayModeChangeTracking() {
        if (!this.enabled) {
            return;
        }

        const mediaQuery = window.matchMedia('(display-mode: standalone)');

        try {
            mediaQuery.addEventListener('change', (evt) => {
                ReactGA.event({
                    category: 'display mode',
                    label: this.landingName,
                    action: `PWA display mode changed to ${evt.matches ? 'PWA Display' : 'Browser Display'}`,
                });
            });
        } catch (e1) {
            try {
                mediaQuery.addEventListener('change', (evt) => {
                    ReactGA.event({
                        category: 'display mode',
                        label: this.landingName,
                        action: `PWA display mode changed to ${evt.matches ? 'PWA Display' : 'Browser Display'}`,
                    });
                });
            } catch (e2) {
                Sentry.captureException(e2);
            }
        }
    }

    pushNotificationPermission(permission) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: 'Click',
            action: 'push notification permission: ' + permission,
            label: this.landingName,
        });
    }

    customEvent(category, action) {
        if (!this.enabled) {
            return;
        }
        ReactGA.event({
            category: category,
            action: action,
            label: this.landingName,
        });
    }

    /**
     * Sends event to Google Analytics
     *
     * @param name - string (required) - a recommended event -> https://developers.google.com/tag-platform/gtagjs/reference/events <- or a custom event
     * @param params - object (optional) according to the documentation at the link above
     */
    gaEvent(name, params) {
        if (!this.enabled) {
            return;
        }

        ReactGA.event(name, params);
    }

    /**
     * Sends event to Google Analytics
     *
     * @param category - string (required) - one of category "game" || "error" || "push" || "ad" || "consent"
     * @param action - string (optional) event/action name
     * @param value - number (optional) additional numeric value
     */
    basicGaEvent(category, action, value) {
        if (!this.enabled) return;
        const actionValue = action
            .replace(/:/g, '_')
            .replace(/\s+/g, '')
            .toLowerCase();

        const options = {
            category: category,
            action: actionValue,
            label: this.landingName,
        };

        if (value) options['value'] = value;

        ReactGA.event({ ...options });

        !isProd() && consoleInfoLog(`google analytics: ${actionValue}`);
        action.length > 40 &&
            Sentry.withScope(function (scope) {
                scope.setLevel('info');
                Sentry.captureException(
                    new Error(
                        `Google Analytics event name is too long: ${action}, length: ${action.length}`
                    )
                );
            });
    }
}
