import React, { Component, createContext } from 'react';

const PushNotificationContext = createContext(null);

class PushNotificationProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPushPhase: null,
            pushPromptTotal: null,
            isNativePromptWasClosed: false,
            pushCallSource: '',
            pushViews: this.getPushViews(),
            pushStatus: this.getPushStatus(), // 'Default', 'Called', 'Granted' or 'Denied'

            updateIsNativePromptWasClosed: this.updateIsNativePromptWasClosed,
            updatePushViews: this.updatePushViews,
            setPushPromptTotal: this.setPushPromptTotal,
            setPushStatus: this.setPushStatus,
            updatePushCallSource: this.updatePushCallSource,
        };
    }

    getPushViews = () => {
        return parseInt(localStorage.getItem('pushViews') || 0);
    };

    updatePushViews = () => {
        this.setState((prevState) => {
            localStorage.setItem('pushViews', prevState.pushViews + 1);

            return { ...prevState, pushViews: prevState.pushViews + 1 };
        });
    };

    getPushStatus = () => {
        if (!('Notification' in window)) return 'Denied';

        const status = localStorage.getItem('pushStatus');

        return !status || status === 'Called' ? 'Default' : status;
    };

    setPushStatus = (pushStatus) => {
        this.setState({ pushStatus });
        localStorage.setItem('pushStatus', pushStatus);
    };

    setPushPromptTotal = (number) => {
        this.setState({ pushPromptTotal: number });
    };

    updateIsNativePromptWasClosed = (value) => {
        this.setState({ isNativePromptWasClosed: value });
    };

    updatePushCallSource = (source) => {
        this.setState({ pushCallSource: source });
    };

    updateShowPushPhase = () => {
        const { pushStatus, pushViews, pushPromptTotal } = this.state;
        const shouldShowPushPhase =
            (pushStatus === 'Default' || pushStatus === 'Called') &&
            pushViews < pushPromptTotal;

        this.setState({ showPushPhase: shouldShowPushPhase });
        localStorage.setItem('showPushPhase', shouldShowPushPhase.toString());
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pushPromptTotal !== this.state.pushPromptTotal) {
            this.updateShowPushPhase();
        }
        if (prevState.pushViews !== this.state.pushViews) {
            this.updateShowPushPhase();
        }
        if (prevState.pushStatus !== this.state.pushStatus) {
            this.updateShowPushPhase();
        }
    }

    render() {
        return (
            <PushNotificationContext.Provider value={this.state}>
                {this.props.children}
            </PushNotificationContext.Provider>
        );
    }
}

export { PushNotificationProvider, PushNotificationContext };
