import React, { createContext, useEffect, useState } from 'react';
import {
    isFirefox,
    isOpera,
    isSafari,
    isSamsungBrowser,
} from 'react-device-detect';

const getPwaPhaseViews = () => {
    return parseInt(localStorage.getItem('pwaPhaseViews') || 0);
};

/**
 * Checks if app is installable as pwa
 *
 * return boolean
 */
const isPwaInstallable = () => {
    return (
        window.BeforeInstallPromptEvent &&
        !isOpera &&
        !isSamsungBrowser &&
        !isFirefox &&
        !isSafari
    );
};

/**
 * Checks if app is installed as PWA
 *
 * return 'Installed' | 'NotInstalled' | 'NotSupported'
 */
const getPWAState = () => {
    if (localStorage.getItem('pwa') === 'Installed') {
        return 'Installed';
    } else if (!isPwaInstallable()) {
        return 'NotSupported';
    } else {
        return 'NotInstalled';
    }
};

const PwaContext = createContext(null);

const PwaProvider = ({ children }) => {
    const [deferredPwaPrompt, setDeferredPwaPrompt] = useState(null);
    const [pwaPromptResult, setPwaPromptResult] = useState(undefined);
    const [showPwaPhase, setShowPwaPhase] = useState(undefined);
    const [pwaPhaseTotal, setPwaPhaseTotal] = useState(undefined);
    const [isPwaInstalled, setIsPwaInstalled] = useState(() => getPWAState());
    const [pwaPhaseViews, setPwaPhaseViews] = useState(() =>
        getPwaPhaseViews()
    );

    useEffect(() => {
        isPwaInstalled === 'NotInstalled' && addPwaInstallEventListeners();
    }, []);

    useEffect(() => {
        if (pwaPhaseTotal !== undefined) {
            const curShowPwaPhase =
                isPwaInstalled === 'NotInstalled' &&
                pwaPhaseTotal > pwaPhaseViews;

            setShowPwaPhase(curShowPwaPhase);
            localStorage.setItem('showPwaPhase', curShowPwaPhase.toString());
        }
    }, [pwaPhaseTotal, pwaPhaseViews, isPwaInstalled]);

    const updatePwaPhaseViews = () => {
        setPwaPhaseViews((prevValue) => {
            localStorage.setItem('pwaPhaseViews', `${prevValue + 1}`);

            return prevValue + 1;
        });
    };

    const updateIsPwaInstalled = (value) => {
        setIsPwaInstalled(value);
        localStorage.setItem('pwa', value);
    };

    const addPwaInstallEventListeners = () => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
            setDeferredPwaPrompt(e); // Stash the event so it can be triggered later.
        });
        window.addEventListener('appinstalled', () => {
            setDeferredPwaPrompt(null);
        });
    };

    const showInstallPrompt = (
        gameGA,
        ga,
        deferredPrompt,
        callSource,
        updateLead
    ) => {
        if (!deferredPrompt) {
            updateIsPwaInstalled('NotInstalled');
            setPwaPromptResult((prev) => (!prev ? 'dismissed' : prev + 1));
            gameGA?.sendDesignEvent(`Pwa:CallInstallFailed:${callSource}`);
            ga?.basicGaEvent('pwa', `call_install_failed_${callSource}`);
            return;
        }

        gameGA?.sendDesignEvent(`Pwa:CallInstall:${callSource}`);
        ga?.basicGaEvent('pwa', `call_install_${callSource}`);

        deferredPrompt
            .prompt() // trigger browser install prompt.
            .then(({ outcome }) => {
                if (outcome === 'accepted') {
                    updateIsPwaInstalled('Installed');
                    setPwaPromptResult('accepted');
                    setDeferredPwaPrompt(null);
                    updateLead && updateLead({ pwa_install: 'true' });
                    gameGA?.sendDesignEvent(`Pwa:Installed:${callSource}`);
                    ga?.basicGaEvent('pwa', `installed_${callSource}`);
                }
                if (outcome === 'dismissed') {
                    updateIsPwaInstalled('NotInstalled');
                    setPwaPromptResult((prev) =>
                        !prev ? 'dismissed' : prev + 1
                    );
                    gameGA?.sendDesignEvent(
                        `Pwa:InstallDeclined:${callSource}`
                    );
                    ga?.basicGaEvent('pwa', `install_declined_${callSource}`);
                }
            });
    };

    return (
        <PwaContext.Provider
            value={{
                showPwaPhase,
                setPwaPhaseTotal,
                deferredPwaPrompt,
                isPwaInstalled,
                pwaPromptResult,
                updatePwaPhaseViews,
                updateIsPwaInstalled,
                showInstallPrompt,
                addPwaInstallEventListeners,
            }}>
            {children}
        </PwaContext.Provider>
    );
};

export { PwaProvider, PwaContext, isPwaInstallable, getPWAState };
