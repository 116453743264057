import React, { memo, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import OneSignal from 'react-onesignal';
import checkAdBlockers from 'App/Helpers/checkAdBlockers';
import { capitalize, updateUrlHash } from 'App/helpers';
import BubbleLoader from 'App/React/_UI/BubbleLoader';
import { PushNotificationContext, PwaContext } from '../../providers';
import { initOneSignal } from './initOneSignal';
import thumbUp from '../../assets/emoji-like.svg';
import freeIcon from '../../assets/free-icon.svg';
import registrationIcon from '../../assets/registration-icon.svg';
import permissionIcon from '../../assets/permission-icon.svg';
import logo from '../../assets/logo.svg';
import snakeX from '../../assets/snake6.svg';
import './styles.scss';

const PushPhase = ({
    adBlocker,
    setPhase,
    updateState,
    createPushLeadHandler,
    gameGA,
    ga,
}) => {
    const [inited, setInited] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const {
        pushStatus,
        setPushStatus,
        updatePushViews,
        isNativePromptWasClosed,
        updateIsNativePromptWasClosed,
    } = useContext(PushNotificationContext);
    const { showPwaPhase } = useContext(PwaContext);

    useEffect(() => {
        !adBlocker && updatePushViews();
        updateUrlHash('2');

        if (Notification?.permission === 'default') {
            initOneSignal(
                createPushLeadHandler,
                setPushStatus,
                setInited,
                gameGA,
                ga
            );
            gameGA.sendDesignEvent('Push:PageShow');
            ga.basicGaEvent('push', 'push_page_show');
        } else {
            setPhase(showPwaPhase ? 'pwa' : 'game');
        }
    }, []);

    useEffect(() => {
        if (isNativePromptWasClosed) {
            let newPushStatus;

            if (pushStatus === 'Granted' || pushStatus === 'Denied') {
                newPushStatus = pushStatus;
                gameGA.sendDesignEvent(`Push:${pushStatus}:LP`);
                ga.basicGaEvent('push', `${pushStatus}_lp`);
            } else {
                newPushStatus = 'Default';
                gameGA.sendDesignEvent('Push:NativePromptClosed:LP');
                ga.basicGaEvent('push', 'native_prompt_closed_lp');
            }

            setPushStatus(newPushStatus);
            setPhase(showPwaPhase ? 'pwa' : 'game');
            updateIsNativePromptWasClosed(false);
        }
    }, [pushStatus, isNativePromptWasClosed]);

    const buttonClickHandler = (buttonName) => {
        checkAdBlockers((adsBlocked) => {
            if (adsBlocked) {
                updateState({
                    showAdBlockerModal: true,
                    adBlocker: adsBlocked,
                });
            } else {
                if (buttonName === 'allow') {
                    setDisableButton(true);
                    OneSignal.showNativePrompt()
                        .then(() => updateIsNativePromptWasClosed(true))
                        .catch(() => {
                            setPhase(showPwaPhase ? 'pwa' : 'game');
                            Sentry.captureException(
                                new TypeError(
                                    "OneSignal doesn't exist or isn't supported"
                                )
                            );
                        });
                }
                if (buttonName === 'continue') {
                    setPhase(showPwaPhase ? 'pwa' : 'game');
                }
            }
        });
        gameGA.sendDesignEvent(`Push:${capitalize(buttonName)}`);
        ga.basicGaEvent('push', `push_${buttonName}`);
    };

    if (!inited)
        return (
            <BubbleLoader backgroundColor={'#440494'} loaderColor={'#FFFFFF'} />
        );

    return (
        <div className={'modal-push_overlay'}>
            <div className={'modal-push_header'}>
                <img
                    src={logo}
                    width={287}
                    height={102}
                    alt='snake game logo'
                    className={'modal-push_logo'}
                />
            </div>
            <div className={'modal-push_content'}>
                <div className={'modal-push_title'}>
                    Click Allow to Get Freebies and Updates!
                </div>
                <div className={'modal-push_row'}>
                    <div className={'modal-push_column'}>
                        <img
                            src={freeIcon}
                            width={47}
                            height={47}
                            alt='free icon'
                            className={'modal-push_icon'}
                        />
                        <div className={'modal-push_span'}>
                            100% Free - No Credit Card Required
                        </div>
                    </div>
                    <div className={'modal-push_column'}>
                        <img
                            src={registrationIcon}
                            width={47}
                            height={47}
                            alt='snake game registration '
                            className={'modal-push_icon'}
                        />
                        <div className={'modal-push_span'}>
                            No Registration Needed
                        </div>
                    </div>
                    <div className={'modal-push_column'}>
                        <img
                            src={permissionIcon}
                            width={47}
                            height={47}
                            alt='snake game permission '
                            className={'modal-push_icon'}
                        />
                        <div className={'modal-push_span'}>
                            No App Permissions
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        onClick={(e) => buttonClickHandler(e.currentTarget.id)}
                        id={'allow'}
                        disabled={disableButton}
                        className={'play-button'}>
                        Allow{' '}
                        <img
                            src={thumbUp}
                            alt='thumb up emoji'
                            className={'modal-push_button-emoji'}
                        />
                    </button>
                </div>
                <button
                    onClick={(e) => buttonClickHandler(e.currentTarget.id)}
                    id={'continue'}
                    className={'modal-push_button-continue'}>
                    Continue to game
                </button>
            </div>
            <div className={'modal-push_footer_wrapper'}>
                <div className={'modal-push_footer'}></div>
                <img
                    src={snakeX}
                    width={211}
                    height={137}
                    alt='snakey game'
                    className={'modal-push_snake'}
                />
            </div>
        </div>
    );
};

export default memo(PushPhase);
