import OneSignal from 'react-onesignal';
import * as Sentry from '@sentry/browser';
import { consoleInfoLog } from 'App/Helpers/consoleInfoLog';
import { capitalize } from '/App/helpers';
import { oneSignalConfig } from '../../config';

export const initOneSignal = (
    createPushLeadHandler,
    setPushStatus,
    setInited,
    gameGA,
    ga
) => {
    OneSignal.init(oneSignalConfig)
        .then(() => {
            setInited && setInited(true);

            OneSignal.on('subscriptionChange', (subscribed) => {
                if (subscribed) {
                    OneSignal.getUserId().then(async (playerId) => {
                        createPushLeadHandler(playerId);
                        consoleInfoLog(`OneSignal UserId: ${playerId}`);
                    });
                    Sentry.withScope(function (scope) {
                        scope.setLevel('info');
                        Sentry.captureException(
                            new Error('User allowed web push on native prompt')
                        );
                    });
                }
                consoleInfoLog(`OneSignal subscriptionChange to ${subscribed}`);
            });

            OneSignal.on('notificationPermissionChange', (permissionChange) => {
                const permission = capitalize(permissionChange.to); // can be 'granted' or 'denied'

                setPushStatus(permission);
                consoleInfoLog(
                    `OneSignal notificationPermissionChange to ${permission}`
                );
            });

            OneSignal.on('permissionPromptDisplay', function () {
                setPushStatus('Called');
                gameGA.sendDesignEvent('Push:NPopupShow');
                ga.basicGaEvent('push', 'native_popup_show');
            });
            consoleInfoLog('Inited OneSignal', '#f3aa4e', '#094205');
        })
        .catch((e) => {
            consoleInfoLog('OneSignal init failed', '#f3aa4e', '#094205');
            Sentry.captureException(e);
        });
};
