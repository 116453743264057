import React, { useState } from 'react';
import { StyleSheetManager } from 'styled-components';
import { Tabs, Tab, Content, TextBox, TabList } from './TabsStyles';
import step1 from '../../../../assets/tutorial-step-1.svg';
import step2 from '../../../../assets/tutorial-step-2.svg';
import step3 from '../../../../assets/tutorial-step-3.svg';
import step4 from '../../../../assets/tutorial-step-4.svg';
import step5 from '../../../../assets/tutorial-step-5.svg';
import play from '../../../../assets/play.svg';
import snakeBig from '../../../../assets/big-snake.svg';
import LazyImg from 'App/React/_UI/LazyImg';
import './styles.scss';

const Tutorial = ({ onBtnClick }) => {
    const [active, setActive] = useState(0);

    const handleClick = (e) => {
        const index = parseInt(e.target.id, 0);

        if (index !== active) {
            setActive(index);
        }
    };

    return (
        <StyleSheetManager shouldForwardProp={(prop) => !prop.startsWith('$')}>
            <section className={'container tutorial-section'}>
                <div className={'wrapper tutorial'}>
                    <div className={'title-wrapper'}>
                        <h2 className={'title'}>
                            <span className={'highlighted'}>Tutorial</span>
                        </h2>
                        <p className={'title-text'}>
                            Please click on the numbers in the bottom to see all
                            the steps:
                        </p>
                    </div>
                    <div className={'steps-wrapper'}>
                        <div className={'box-shadow'}></div>
                        <Tabs>
                            <Content $active={active === 0}>
                                <img
                                    src={step1}
                                    width={291}
                                    height={509}
                                    alt='snake tutorial step 1'
                                    loading='lazy'
                                />
                                <TextBox>
                                    Swipe your finger on the screen to control
                                    the movement of the snake.
                                </TextBox>
                            </Content>
                            <Content $active={active === 1}>
                                <img
                                    src={step2}
                                    width={291}
                                    height={509}
                                    alt='snake tutorial step 2'
                                    loading='lazy'
                                />
                                <TextBox>
                                    If your snake has a number of balls greater
                                    than the number on a block - you can destroy
                                    it!
                                </TextBox>
                            </Content>
                            <Content $active={active === 2}>
                                <img
                                    src={step3}
                                    width={291}
                                    height={509}
                                    alt='snake tutorial step 3'
                                    loading='lazy'
                                />
                                <TextBox>
                                    But when you do, your snake will lose that
                                    number of balls. Grow your snake again by
                                    swiping additional balls.
                                </TextBox>
                            </Content>
                            <Content $active={active === 3}>
                                <img
                                    src={step4}
                                    width={291}
                                    height={509}
                                    alt='snake tutorial step 4'
                                    loading='lazy'
                                />
                                <TextBox>
                                    Destroy the "star block" to get super power!
                                </TextBox>
                            </Content>
                            <Content $active={active === 4}>
                                <img
                                    src={step5}
                                    width={291}
                                    height={509}
                                    alt='snake tutorial step 5'
                                    loading='lazy'
                                />
                                <TextBox>
                                    Get to the next level by reaching the finish
                                    line.
                                </TextBox>
                            </Content>
                            <TabList>
                                <Tab
                                    onClick={handleClick}
                                    $active={active === 0}
                                    id='0'>
                                    1
                                </Tab>
                                <Tab
                                    onClick={handleClick}
                                    $active={active === 1}
                                    id='1'>
                                    2
                                </Tab>
                                <Tab
                                    onClick={handleClick}
                                    $active={active === 2}
                                    id='2'>
                                    3
                                </Tab>
                                <Tab
                                    onClick={handleClick}
                                    $active={active === 3}
                                    id='3'>
                                    4
                                </Tab>
                                <Tab
                                    onClick={handleClick}
                                    $active={active === 4}
                                    id='4'>
                                    5
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                    <h2 className={'title-action'}>
                        Are you <span className={'highlighted'}>up</span> for
                        the challenge?
                    </h2>
                    <button
                        onClick={() => onBtnClick()}
                        className={'play-button'}>
                        Play For Free{' '}
                        <img
                            src={play}
                            width={14}
                            height={17}
                            alt='play'
                            className={'button-emoji'}
                            loading='lazy'
                        />
                    </button>
                </div>
                <LazyImg
                    className={'tutorial-snake'}
                    url={snakeBig}
                    width={436}
                    height={296}
                    alt='big snake game'
                />
            </section>
        </StyleSheetManager>
    );
};

export default Tutorial;
