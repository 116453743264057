import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import {
    PushNotificationContext,
    PushNotificationProvider,
    PwaContext,
    PwaProvider,
} from './providers';
import App from './App';

const AppWithCombinedContexts = () => {
    const push = useContext(PushNotificationContext);
    const pwa = useContext(PwaContext);

    return <App pwaProvider={pwa} pushProvider={push} />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <PushNotificationProvider>
        <PwaProvider>
            <AppWithCombinedContexts />
        </PwaProvider>
    </PushNotificationProvider>
);
