import styled from 'styled-components';

export const Tabs = styled.div`
    width: 100%;
`;

export const TabList = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
`;

export const Tab = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(20% + 1px);
    outline: none;
    cursor: pointer;
    aspect-ratio: 1 / 1;
    font-size: 37px;
    font-weight: 700;
    color: #4900b5;
    text-shadow: 0 -1px 1px #7508ff;
    background-color: ${({ $active }) => ($active ? 'transparent' : '#37027B')};
    border-style: solid;
    border-width: 0.4px;
    border-color: #7500ff;
    border-top: ${({ $active }) => ($active ? 'none' : '')};
    border-bottom-left-radius: 8%;
    border-bottom-right-radius: 8%;
    transition: all 0.3s ease-in-out;

    :not(:first-child) {
        margin-left: ${({ $active }) => ($active ? '0' : '-1px')};
    }

    @media (min-width: 414px) {
        font-size: 50px;
        border-width: 0.55px;
    }
    @media (min-width: 576px) {
        font-size: 72px;
        border-width: 0.8px;
        text-shadow: 0 -2px 2px #7508ff;
    }
    @media (min-width: 768px) {
        font-size: 84px;
        border-width: 1px;
        text-shadow: 0 -3px 3px #7508ff;
    }
    @media (min-width: 1024px) {
        font-size: 129px;
        border-width: 1.4px;
        text-shadow: 0 -4px 4px #7508ff;
    }
    @media (min-width: 1920px) {
        font-size: 185px;
        border-width: 2px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 26px 32px;
    display: ${({ $active }) => ($active ? '' : 'none')};
    transition: all 0.5s ease-in-out;

    @media (min-width: 414px) {
        padding: 24px 30px 32px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    @media (min-width: 576px) {
        padding: 40px 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-evenly;
        padding: 68px 0;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
    }
    @media (min-width: 1024px) {
        position: relative;
        padding: 68px 0 120px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    @media (min-width: 1920px) {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
    }

    & > img {
        width: 180px;
        height: auto;

        @media (min-width: 576px) {
            width: 230px;
        }
        @media (min-width: 768px) {
            width: 277px;
        }
    }
`;

export const TextBox = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 98px;
    margin-top: 16px;
    padding: 14px;
    font-size: 14px;

    @media (min-width: 414px) {
        width: 260px;
        padding: 14px 16px;
    }
    @media (min-width: 576px) {
        margin-top: 28px;
        padding: 14px 18px;
    }
    @media (min-width: 768px) {
        margin-top: 0;
        font-size: 18px;
    }
    @media (min-width: 1024px) {
        position: absolute;
        margin-left: 60%;
    }
    @media (min-width: 1440px) {
        margin-left: 50%;
    }
`;
