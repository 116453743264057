import React from 'react';
import logo from '../../assets/logo.svg';
import snakeY from '../../assets/snakey.svg';
import './styles.scss';

const AdBlockerModal = ({ adBlockOn }) => {
    const onClickHandler = () => {
        if (adBlockOn) {
            window.location.reload();
        }
    };

    return (
        <div className={'modal-adblock_overlay'}>
            <div className={'modal-adblock_header'}>
                <img src={logo} alt='' className={'modal-adblock_logo'} />
            </div>
            <div className={'modal-adblock_content-wrapper'}>
                <div className={'modal-adblock_content'}>
                    <div className={'modal-adblock_title'}>
                        Ad-block Detected!
                    </div>
                    <p className={'modal-adblock_body'}>
                        We detected that you are using an ad blocker,{' '}
                        <span className={'yellow'}>please turn it off.</span>
                    </p>
                    <button
                        onClick={onClickHandler}
                        className={'play-button modal-adblock_button'}>
                        Yes, Ad-block is off
                    </button>
                </div>
            </div>
            <div className={'modal-adblock_snack-wrapper'}>
                <img
                    src={snakeY}
                    alt='snakey game'
                    className={'modal-adblock_snake'}
                />
            </div>
        </div>
    );
};

export default AdBlockerModal;
