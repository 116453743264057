import React, { memo, useContext, useEffect, useState } from 'react';
import checkAdBlockers from 'App/Helpers/checkAdBlockers';
import { capitalize, updateUrlHash } from 'App/helpers';
import { PwaContext } from '../../providers';
import logo from '../../assets/logo.svg';
import lightning from '../../assets/lightning-icon.svg';
import registrationIcon from '../../assets/registration-icon.svg';
import permissionIcon from '../../assets/permission-icon.svg';
import thumbUp from '../../assets/emoji-like.svg';
import snakeX from '../../assets/snake6.svg';
import './styles.scss';

const InstallPhase = ({
    updateState,
    updateLead,
    setPhase,
    adBlocker,
    gameGa,
    ga,
}) => {
    const [disableButton, setDisableButton] = useState(false);
    const {
        deferredPwaPrompt,
        showInstallPrompt,
        updatePwaPhaseViews,
        pwaPromptResult,
    } = useContext(PwaContext);

    useEffect(() => {
        !adBlocker && updatePwaPhaseViews();
        updateUrlHash('3');
        gameGa?.sendDesignEvent('Pwa:PageShow');
        ga?.basicGaEvent('pwa', 'pwa_page_show');
    }, []);

    useEffect(() => {
        if (pwaPromptResult !== undefined) {
            setPhase('game');
        }
    }, [pwaPromptResult]);

    const buttonClickHandler = (buttonName) => {
        checkAdBlockers((adsBlocked) => {
            if (adsBlocked) {
                updateState({
                    showAdBlockerModal: true,
                    adBlocker: adsBlocked,
                });
            } else {
                if (buttonName === 'install') {
                    setDisableButton(true);
                    showInstallPrompt(
                        gameGa,
                        ga,
                        deferredPwaPrompt,
                        'LP',
                        updateLead
                    );
                } else if (buttonName === 'continue') {
                    setPhase('game');
                }
            }
        });
        gameGa?.sendDesignEvent(`Pwa:${capitalize(buttonName)}`);
        ga?.basicGaEvent('pwa', `pwa_${buttonName}`);
    };

    return (
        <div className={'modal-pwa_overlay'}>
            <div className={'modal-pwa_header'}>
                <img
                    src={logo}
                    width={287}
                    height={102}
                    alt='snake game logo'
                    className={'modal-push_logo'}
                />
            </div>
            <div className={'modal-pwa_content'}>
                <div className={'modal-pwa_title'}>
                    Get the Snake game on your device and play anytime you want
                </div>
                <div className={'modal-pwa_row'}>
                    <div className={'modal-pwa_column'}>
                        <img
                            src={lightning}
                            width={47}
                            height={47}
                            alt='free icon'
                            className={'modal-pwa_icon'}
                        />
                        <div className={'modal-pwa_span'}>
                            Fast and lightweight
                        </div>
                    </div>
                    <div className={'modal-pwa_column'}>
                        <img
                            src={registrationIcon}
                            width={47}
                            height={47}
                            alt='snake game registration'
                            className={'modal-pwa_icon'}
                        />
                        <div className={'modal-pwa_span'}>
                            No Registration Needed
                        </div>
                    </div>
                    <div className={'modal-pwa_column'}>
                        <img
                            src={permissionIcon}
                            width={47}
                            height={47}
                            alt='snake game permission'
                            className={'modal-pwa_icon'}
                        />
                        <div className={'modal-pwa_span'}>
                            No App Permissions
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        id={'install'}
                        className={'play-button'}
                        disabled={disableButton}
                        onClick={(e) => buttonClickHandler(e.currentTarget.id)}>
                        <div className='button-text'>
                            {' '}
                            Install for Free{' '}
                            <img
                                src={thumbUp}
                                alt='thumb up emoji'
                                className={'modal-pwa_button-emoji'}
                            />
                        </div>
                    </button>
                </div>
                <button
                    id={'continue'}
                    disabled={disableButton}
                    className={'modal-pwa_button-continue'}
                    onClick={(e) => buttonClickHandler(e.currentTarget.id)}>
                    Continue to game
                </button>
            </div>
            <div className={'modal-pwa_footer_wrapper'}>
                <div className={'modal-pwa_footer'}></div>
                <img
                    src={snakeX}
                    width={211}
                    height={137}
                    alt='snakey game'
                    className={'modal-pwa_snake'}
                />
            </div>
        </div>
    );
};

export default memo(InstallPhase);
