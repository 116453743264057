/**
 * Check if container DOM element is iframe element or it has iframe element
 *
 * @param element - <HTMLElement>(required)
 * @param tagName - string(optional)
 *
 * @return boolean
 */
const hasAdElement = (element, tagName) => {
    if (element.tagName === tagName) {
        // If the element is the same as tagName, return true
        return true;
    } else {
        // Otherwise, check each child element recursively
        for (let i = 0; i < element.childElementCount; i++) {
            if (hasAdElement(element.children[i], tagName)) {
                // If any child element has the same element as tagName, return true
                return true;
            }
        }
    }
    // If no element the same as tagName is found, return false
    return false;
};

export default hasAdElement;
