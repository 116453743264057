import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { isMobile } from 'react-device-detect';
import { useAdService } from 'App/Services/AdService';
import { handleCacheControl, updateUrlHash } from 'App/helpers';
import BubbleLoader from 'App/React/_UI/BubbleLoader';
import {
    useGameFocus,
    useGameSession,
    useLoadingTime,
    useSendAnalytics,
    useResizeListenerForBanners,
    useSetGameCanvasDimensions,
} from 'App/Game/hooks';
import { PushNotificationContext, PwaContext } from '../../../../providers';
import { initOneSignal } from '../../../PushPhase/initOneSignal';
import {
    useColorChange,
    useEnablePush,
    usePwaInstall,
    useRewardStarted,
} from './hooks';
import GameAdBanners from '../GameAdBanners';
import './style.scss';

const Game = ({
    gameGa,
    ga,
    gameConfig,
    orientation,
    adProvider,
    createPushLead,
    updateLead,
}) => {
    const [startPreroll, setStartPreroll] = useState(false);
    const [isPrerollStartSent, setIsPrerollStartSent] = useState(false);
    const [initialPushStatus, setInitialPushStatus] = useState('');
    const [initialPwaPromptResult, setInitialPwaPromptResult] = useState('');
    const canvasRef = useRef(null);
    const sideContainerRef = useRef(null);
    const refBannerRight = useRef(null);
    const refBannerLeft = useRef(null);

    const { canvasWidth, canvasHeight } = useSetGameCanvasDimensions();
    const {
        adProvInit,
        consent,
        updateAd,
        prerollCount: prerollEnd,
        rewardReceived,
        setRewardReceived,
    } = useAdService();
    const {
        pushStatus,
        setPushStatus,
        pushCallSource,
        isNativePromptWasClosed,
        updateIsNativePromptWasClosed,
    } = useContext(PushNotificationContext);
    const { isPwaInstalled, pwaPromptResult } = useContext(PwaContext);
    const {
        loadingProgression,
        isLoaded,
        sendMessage,
        addEventListener,
        removeEventListener,
        unityProvider,
    } = useUnityContext({
        loaderUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.loader.js`,
        frameworkUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.framework.js.unityweb`,
        dataUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.data.unityweb`,
        codeUrl: `${gameConfig.buildUrl}WebGL_${gameConfig.buildVersion}.wasm.unityweb`,
        productName: 'Snake Color Brake',
        companyName: 'Reyo Media Cyprus',
        productVersion: gameConfig.buildVersion,
        cacheControl: handleCacheControl,
    });
    useLoadingTime(isLoaded, gameGa, ga);
    useGameFocus(canvasRef);

    useEffect(() => {
        updateUrlHash('4');
        setInitialPwaPromptResult(pwaPromptResult);

        if (!window.OneSignal && pushStatus === 'Default' && gameConfig.popup) {
            try {
                initOneSignal(createPushLead, setPushStatus, null, gameGa, ga);
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const { main, popup } = gameConfig;
            const initPushState = popup ? pushStatus : 'Denied';

            sendMessage(
                'GameConfigurator',
                'SendConfiguration',
                JSON.stringify({
                    ...main,
                    OfferConfiguration: {
                        PWAState: isPwaInstalled,
                        PushState: initPushState,
                        DisplayConfiguration: popup,
                    },
                })
            );
        }
    }, [isLoaded]);

    useEffect(() => {
        setInitialPushStatus(pushStatus);
    }, [pushStatus]);

    useEffect(() => {
        if (isLoaded && pushStatus !== initialPushStatus) {
            sendMessage('Push', 'EnableResult', pushStatus);
        }
    }, [isLoaded, pushStatus]);

    useEffect(() => {
        if (isNativePromptWasClosed) {
            if (pushStatus === 'Granted' || pushStatus === 'Denied') {
                gameGa.sendDesignEvent(
                    `Push:${pushStatus}:Game:${pushCallSource}`
                );
                ga.basicGaEvent('push', `${pushStatus}_game_${pushCallSource}`);
            } else {
                setPushStatus('Default');
                gameGa.sendDesignEvent(
                    `Push:NativePromptClosed:Game:${pushCallSource}`
                );
                ga.basicGaEvent(
                    'push',
                    `native_prompt_closed_game_${pushCallSource}`
                );
            }

            updateIsNativePromptWasClosed(false);
        }
    }, [isLoaded, pushStatus, isNativePromptWasClosed]);

    useEffect(() => {
        if (
            isLoaded &&
            pwaPromptResult &&
            pwaPromptResult !== initialPwaPromptResult
        ) {
            sendMessage('PWA', 'InstallResult', isPwaInstalled);
        }
    }, [isLoaded, pwaPromptResult]);

    useGameSession(gameGa, ga, addEventListener, removeEventListener);
    useSendAnalytics(gameGa, ga, addEventListener, removeEventListener);
    useRewardStarted(addEventListener, removeEventListener);
    useEnablePush(addEventListener, removeEventListener);
    usePwaInstall(
        gameGa,
        ga,
        updateLead,
        addEventListener,
        removeEventListener
    );

    const gameBgColor = useColorChange(addEventListener, removeEventListener);
    const [sideNarrowBannerAd, sideWideBannerAd] = useResizeListenerForBanners(
        sideContainerRef,
        gameGa,
        ga
    );

    useEffect(() => {
        if (
            isLoaded &&
            adProvInit &&
            (sideWideBannerAd || sideNarrowBannerAd)
        ) {
            updateAd(refBannerLeft.current);
            updateAd(refBannerRight.current);
        }
    }, [isLoaded, adProvInit, sideWideBannerAd, sideNarrowBannerAd]);

    useEffect(() => {
        if (rewardReceived) {
            sendMessage('WebApi', 'GrantReward');
            setRewardReceived(false);
        }
    }, [rewardReceived]);

    useEffect(() => {
        if (orientation && isMobile) {
            if (orientation.startsWith('portrait')) {
                sendMessage('WebApi', 'ScreenOrientationChangeTo', 'portrait');
            } else {
                sendMessage('WebApi', 'ScreenOrientationChangeTo', 'landscape');
            }
        }
    }, [orientation]);

    useEffect(() => {
        if (!isPrerollStartSent && startPreroll) {
            setIsPrerollStartSent(true);
            sendMessage('WebApi', 'BlockStartGame');
        }
    }, [startPreroll]);

    useEffect(() => {
        prerollEnd > 0 && startPreroll && setStartPreroll(false);

        if (prerollEnd > 0) {
            sendMessage('WebApi', 'UnblockStartGame');
            setIsPrerollStartSent(false);

            if (canvasRef.current) {
                canvasRef.current.focus();
                canvasRef.current.click();
            }
        }
    }, [prerollEnd]);

    useEffect(() => {
        // todo: change sendMessage method names to BlockGame & UnblockGame
        isLoaded && consent && sendMessage('WebApi', 'PrerollStart');
        isLoaded && !consent && sendMessage('WebApi', 'PrerollCompleted');
    }, [isLoaded, consent]);

    return (
        <>
            <div
                className={'game-container'}
                style={{
                    background: gameBgColor,
                    height: isMobile ? canvasHeight : '100vh',
                }}>
                {!isLoaded && (
                    <BubbleLoader
                        backgroundColor={'#440494'}
                        loaderColor={'#FFFFFF'}
                        loadingProgression={loadingProgression}
                    />
                )}
                <div ref={sideContainerRef} className={'side-container left'}>
                    {sideNarrowBannerAd && adProvider === 'vli' && (
                        <div ref={refBannerLeft} data-ga-id='vli_160x600_left'>
                            <div className='adsbyvli' data-ad-slot='pw_22509' />
                        </div>
                    )}
                    {sideWideBannerAd && adProvider === 'vli' && (
                        <div ref={refBannerLeft} data-ga-id='vli_300x600_left'>
                            <div className='adsbyvli' data-ad-slot='pw_18501' />
                        </div>
                    )}
                    {sideNarrowBannerAd && adProvider === 'adinplay' && (
                        <div
                            ref={refBannerLeft}
                            data-ga-id='adinplay_160x600_left'>
                            <div id='snakecolorbrake-com_160x600_left' />
                        </div>
                    )}
                    {sideWideBannerAd && adProvider === 'adinplay' && (
                        <div
                            ref={refBannerLeft}
                            data-ga-id='adinplay_300x600_left'>
                            <div id='snakecolorbrake-com_300x600_left' />
                        </div>
                    )}
                </div>
                <Unity
                    ref={canvasRef}
                    unityProvider={unityProvider}
                    style={{ width: canvasWidth, height: canvasHeight }}
                    devicePixelRatio={window.devicePixelRatio}
                />
                <div ref={sideContainerRef} className={'side-container right'}>
                    {sideNarrowBannerAd && adProvider === 'vli' && (
                        <div
                            ref={refBannerRight}
                            data-ga-id='vli_160x600_right'>
                            <div className='adsbyvli' data-ad-slot='pw_22508' />
                        </div>
                    )}
                    {sideWideBannerAd && adProvider === 'vli' && (
                        <div
                            ref={refBannerRight}
                            data-ga-id='vli_300x600_right'>
                            <div className='adsbyvli' data-ad-slot='pw_17217' />
                        </div>
                    )}
                    {sideNarrowBannerAd && adProvider === 'adinplay' && (
                        <div
                            ref={refBannerRight}
                            data-ga-id='adinplay_160x600_right'>
                            <div id='snakecolorbrake-com_160x600_right' />
                        </div>
                    )}
                    {sideWideBannerAd && adProvider === 'adinplay' && (
                        <div
                            ref={refBannerRight}
                            data-ga-id='adinplay_300x600_right'>
                            <div id='snakecolorbrake-com_300x600_right' />
                        </div>
                    )}
                </div>
                <GameAdBanners
                    adProvider={adProvider}
                    setStartPreroll={setStartPreroll}
                    removeEventListener={removeEventListener}
                    addEventListener={addEventListener}
                />
            </div>
        </>
    );
};

export default memo(Game);
