import React from 'react';
import SliderSwiper from './components/SliderSwiper';
import control from '../../../../assets/control.png';
import challenging from '../../../../assets/challenging.png';
import strategy from '../../../../assets/strategy.png';
import worldWide from '../../../../assets/worldwide.png';
import experience from '../../../../assets/experience.png';
import endless from '../../../../assets/endless.png';
import play from '../../../../assets/play.svg';
import './styles.scss';

const caruselItems = [
    {
        img: control,
        text: 'Control over a snake',
        description:
            'This fast-paced gameplay gives players control over a snake of balls used to break through blocks and reach high scores.',
        alt: 'control your game',
    },
    {
        img: challenging,
        text: 'Challenging  game',
        description:
            'With the simple control mechanism, players can easily play, diving deep into a game that is challenging to master.',
        alt: 'challenging snake game',
    },
    {
        img: strategy,
        text: 'Strategic needed',
        description:
            'Levels increase in difficulty as players advance, creating a genuinely addictive game that requires a strategic mind.',
        alt: 'snake game strategy',
    },
    {
        img: worldWide,
        text: 'Worldwide distribution',
        description:
            'Compete with friends and players worldwide, earning the chance to rank on leaderboards and show off your mastery.',
        alt: 'snake worldwide game',
    },
    {
        img: experience,
        text: 'Great user experience',
        description:
            'Attractive graphics and animations offer an engaging user experience that brings the game to life.',
        alt: 'new snake game experience',
    },
    {
        img: endless,
        text: 'Endless gameplay',
        description:
            'Once you play, you’re hooked. With no end in sight, this game lasts as long as you do.',
        alt: 'endless snake  game',
    },
];

const Advantages = ({ onBtnClick }) => {
    return (
        <section className={'container advantages colored'}>
            <div className={'wrapper advantages'}>
                <h2 className={'title'}>
                    Why the hype around{' '}
                    <span className={'highlighted nowrap'}>
                        Snake Color Brake?
                    </span>
                </h2>
                <SliderSwiper caruselItems={caruselItems} />
                <h2 className={'title-action'}>
                    Join the millions of players already{' '}
                    <span className={'highlighted'}>enjoying</span> the game!
                </h2>
                <div className='wrapper-button'>
                    <button
                        onClick={() => onBtnClick()}
                        className='play-button'>
                        Play For Free{' '}
                        <img
                            src={play}
                            width={14}
                            height={17}
                            alt='play'
                            className={'button-emoji'}
                            loading='lazy'
                        />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Advantages;
