import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import leftArrow from '../../../../../../assets/left-arrow.svg';
import rightArrow from '../../../../../../assets/right-arrow.svg';
import Card from './Card';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import './styles.scss';

const swiperOptions = {
    breakpoints: {
        1439: {
            slidesPerView: 3,
        },
        700: {
            slidesPerView: 2,
        },
        320: {
            slidesPerView: 1,
        },
    },
    slidesPerView: 3,
    spaceBetween: 35,
    pagination: {
        clickable: false,
        el: '.swiper-pagination',
    },
    navigation: {
        nextEl: '.my-button-next',
        prevEl: '.my-button-prev',
        custom: true,
    },
    modules: [Pagination, Navigation],
};

const SliderSwiper = ({ caruselItems }) => {
    return (
        <Swiper {...swiperOptions}>
            {caruselItems.map((item, index) => (
                <SwiperSlide key={index}>
                    <Card {...item} />
                </SwiperSlide>
            ))}

            <div className='swiper-action'>
                <div className='my-button-prev'>
                    <img src={leftArrow} width={20} height={30} alt='arrow' />
                </div>

                <div className='swiper-pagination' />

                <div className='my-button-next'>
                    <img src={rightArrow} width={20} height={30} alt='arrow' />
                </div>
            </div>
        </Swiper>
    );
};

export default SliderSwiper;
